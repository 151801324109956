import { RightsBaseEnum } from "ui"

enum RightsEnum {
    HeathInsuranceView = 'heathInsurance.view',
    HeathInsuranceViewFinalized = 'heathInsurance.view.finalized',
    HeathInsuranceNew = 'heathInsurance.new',
    LifeInsuranceView = 'lifeInsurance.view',
    LifeInsuranceNew = 'lifeInsurance.new',
    LppView = 'Lpp.view',
    LppNew = 'Lpp.new',
    ClientView = 'client.view',
    ClientNew = 'client.new',
    CanChangeMessageToCompany = 'contract.lifeInsurance.canChangeMessageToCompany',
    BuilderView = 'BuilderView'
}
export const Rights = { ...RightsBaseEnum, ...RightsEnum }

export const acl = {
    "admin": [
        Rights.All,
    ],
    "responsible": [
        Rights.HeathInsuranceView,
        Rights.HeathInsuranceNew,
        Rights.BuilderView,
        Rights.HeathInsuranceViewFinalized,
    ],
    "manager": [
        Rights.HeathInsuranceView,
        Rights.HeathInsuranceNew,
        Rights.BuilderView,
    ],
    "quality": [
        Rights.None,
    ],
    "validator": [
        Rights.None,
    ],
    "advisor": [
        Rights.HeathInsuranceView,
        Rights.HeathInsuranceNew,
        Rights.BuilderView,
    ]
}

export default acl