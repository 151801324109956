

export class Window {

    private static callbacks: Record<string, () => void> = {}

    public static addUnload(name: string, callback: () => void) {
        Window.callbacks['unload' + name] = callback
        window.addEventListener('unload', callback)
    }

    public static removeUnload(name: string) {
        window.removeEventListener('unload', Window.callbacks['unload' + name])
    }

    public static addLoad(name: string, callback: () => void) {
        Window.callbacks['load' + name] = callback
        window.addEventListener('unload', callback)
    }

    public static removeLoad(name: string) {
        window.removeEventListener('unload', Window.callbacks['load' + name])
    }

}