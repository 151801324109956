export const URLS = {
    Homepage: '/',
    InsuranceHealthListing: '/insurance/health/listing',
    InsuranceHealthDetail: '/insurance/health/:id',
    InsuranceHealthAdd: 'insurance/health/add',
    InsuranceLifeListing: '/insurance/life/listing',
    InsuranceLifeAdd: '/insurance/life/add',
    InsuranceLifeDetail: '/insurance/life/:id',
    LppListing: '/lpp/listing',
    CustomerAdd: '/customer/add',
    HumanRessourcesPaidVacation: '/human-ressources/paid-vacation',
    AdminUser: '/admin/user',
    SocialAgenda: '/social/agenda',
    Profil: '/profil',
    Playground: '/playground',
    ContractChatPage: '/builder',
    ContractChatMobilePage: '/builder/:id',
    ContractChatPageQrcode: '/builder/qrcode/:id',
}