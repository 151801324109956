import * as React from "react"
import { Suspense } from "react"
import { createRoot } from 'react-dom/client'
import keycloak from './configs/keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom"
import PrivateRoute from 'shared/helpers/PrivateRoute'
import { Layout } from 'ui/components'
import 'ui/base.css'
import {
    ErrorPage,
    NotFoundPage
} from './pages'
import { useLazy } from 'ui/hooks/useLazy'
import {
    Menu,
} from "./components"
import { AclProvider } from "ui/hooks/useAcl"
import acl, { RightsEnum } from "./configs/acl"
import { getSocket, useSocket } from "ui"
import { initService } from "./services"
import { useSocketStore, SocketStatusEnum } from "shared/stores/useSocketStore"
import { URLS } from "./configs/routing"

const HomePage = useLazy(import('./pages/HomePage/HomePage'), 'HomePage')
const ContractAddPage = useLazy(import('./pages/Insurance/Health/add/ContractAddPage'), 'ContractAddPage')
const ContractListPage = useLazy(import('./pages/Insurance/Health/list/ContractListPage'), 'ContractListPage')
const ContractDetailPage = useLazy(import('./pages/Insurance/Health/detail/ContractDetailPage'), 'ContractDetailPage')
const ProfilPage = useLazy(import('./pages/ProfilPage/ProfilPage'), 'ProfilPage')
const PaidVacationPage = useLazy(import('./pages/PaidVacationPage/PaidVacationPage'), 'PaidVacationPage')
const LppListingPage = useLazy(import('./pages/LppListingPage/LppListingPage'), 'LppListingPage')
const SocialAgendaPage = useLazy(import('./pages/SocialAgendaPage/SocialAgendaPage'), 'SocialAgendaPage')
const ContractChatPage = useLazy(import('./pages/ContractChatPage/ContractChatPage'), 'ContractChatPage')
const ContractChatMobilePage = useLazy(import('./pages/ContractChatPage/ContractChatMobilePage'), 'ContractChatMobilePage')
const WaitingPage = useLazy(import('./pages/WaitingPage/WaitingPage'), 'WaitingPage')

const [createSocketQuality] = useSocket({
    url: import.meta.env.VITE_APP_SOCKET_URL,
    namespace: import.meta.env.VITE_APP_NAMESPACE,
})

const [createSocketSms] = useSocket({
    url: 'https://sms.swissvest.app',
    name: "sms",
})

const onLogin = (token: string) => {
    const socket = createSocketQuality(token)
    const setStatus = useSocketStore.getState().setStatus
    socket?.on('connect', () => {
            setStatus(SocketStatusEnum.Connected)
        }).on('disconnect', () => {
            setStatus(SocketStatusEnum.Disconnected)
        })
}

const onTokenUpdate = (token: string) => {
    const socket = getSocket()
    socket.tokenUpdate(token)
}

const router = createBrowserRouter([
    {
        element: (
            <PrivateRoute onLogin={onLogin} initService={initService} onTokenUpdate={onTokenUpdate}>
                <Layout menu={<Menu />} headerClassName="bg-tyrianPurple text-white" />
            </PrivateRoute>
        ),
        path: URLS.Homepage,
        //errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Suspense fallback={<>...</>}>
                    <HomePage />
                </Suspense>
            },
            {
                path: URLS.InsuranceHealthListing,
                element: <Suspense fallback={<>...</>}>
                    <ContractListPage />
                </Suspense>
            },
            {
                path: URLS.InsuranceHealthAdd,
                element: <Suspense fallback={<>...</>}>
                    <ContractAddPage />
                </Suspense>
            },
            {
                path: URLS.InsuranceHealthDetail,
                element: <Suspense fallback={<>...</>}>
                    <ContractDetailPage />
                </Suspense>
            },
            {
                path: URLS.InsuranceLifeListing,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage label="Listing des assurances vie" />
                </Suspense>
            },
            {
                path: URLS.InsuranceLifeAdd,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage />
                </Suspense>
            },
            {
                path: URLS.InsuranceLifeDetail,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage />
                </Suspense>
            },
            {
                path: URLS.LppListing,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage label="listing des LPP" />
                </Suspense>
            },
            {
                path: URLS.CustomerListing,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage label="listing clients" />
                </Suspense>
            },
            {
                path: URLS.CustomerAdd,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage label="ajout d'un client" />
                </Suspense>
            },
            {
                path: URLS.HumanRessourcesPaidVacation,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage label="gestion des vacances / absences" />
                </Suspense>
            },
            {
                path: URLS.SocialAgenda,
                element: <Suspense fallback={<>...</>}>
                    <WaitingPage label="agenda interne" />
                </Suspense>
            },
            {
                path: URLS.Profil,
                element: <Suspense fallback={<>...</>}>
                    <ProfilPage />
                </Suspense>
            },
            {
                path: URLS.ContractChatPage,
                element: <Suspense fallback={<>...</>}>
                    <ContractChatPage />
                </Suspense>
            },
            {
                path: URLS.ContractChatMobilePage,
                element: <Suspense fallback={<>...</>}>
                    <ContractChatMobilePage />
                </Suspense>
            },
            {
                element: <NotFoundPage />,
                path: "*",
            },
        ]
    }
])

let container: HTMLElement | null = null
document.addEventListener('DOMContentLoaded', () => {
    if (!container) {
        container = document.getElementById('app') as HTMLElement;
        const root = createRoot(container)
        root.render(
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={{
                    onLoad: 'login-required',
                    checkLoginIframe: false,
                    enableLogging: true
                }}
            >
                <AclProvider<RightsEnum> acl={acl}>
                    <RouterProvider router={router} />
                </AclProvider>
            </ReactKeycloakProvider>
        )
    }
})

// spectacular admin dashboard
// https://dribbble.com/shots/5790178-Dark-Dashboard
// https://www.fiverr.com/olumitide/create-an-amazing-admin-dashboard-template
// https://dribbble.com/shots/12843899/attachments/4443296?mode=media
// take a look to https://www.keycloakify.dev/