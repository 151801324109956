/**
 * The function `padWithLeadingChar` pads a given number or string with a specified character to a
 * specified total length.
 * @param {number | string} text - The `text` parameter can be either a number or a string.
 * @param {number} totalLength - The total length parameter specifies the desired length of the
 * resulting string after padding.
 * @param {string} [character=0] - The `character` parameter is an optional parameter that specifies
 * the character to use for padding. By default, it is set to `'0'`, which means that if no character
 * is provided, the function will pad the text with leading zeros. However, you can also provide a
 * different character if you want
 * @returns The function `padWithLeadingChar` returns a string with the specified `text` padded with
 * the `character` to the left, so that the resulting string has a length of `totalLength`.
 */
export function padWithLeadingChar(text: number | string, totalLength: number, character = '0'): string {
    const str = isString(text) ? text : text.toString()
    return str.padStart(totalLength, character)
}

/**
 * The function checks if a given value is a string.
 * @param {unknown} str - The parameter `str` is of type `any`, which means it can accept any data type.
 * @returns a boolean value indicating whether the input parameter is a string or not.
 */
export function isString(str: unknown): str is string {
    return typeof str === 'string'
}

/* The `export enum NamingConventionEnum` is an enumeration in TypeScript that defines different naming
conventions. Each value in the enumeration represents a specific naming convention, such as camel
case, snake case, pascal case, kebab case, screaming snake case, camel snake case, and dot case. */
export enum NamingConventionEnum {
    Camel = 'camel',
    Snake = 'snake',
    Pascal = 'pascal',
    Kebab = 'kebab',
    ScreamingSnake = 'screamingsnake',
    CamelSnake = 'camelsnake',
    Dot = 'dot',
}

/**
 * The function `namingConventionConvert` converts a given input string to a specified naming
 * convention.
 * @param {string} input - The `input` parameter is a string that represents the name or identifier
 * that you want to convert to a different naming convention.
 * @param {NamingConventionEnum} target - The `target` parameter in the `namingConventionConvert`
 * function is of type `NamingConventionEnum`. It is an enumeration that represents different naming
 * conventions. The possible values for `target` are:
 * @returns The function `namingConventionConvert` returns the converted string based on the target
 * naming convention.
 */
export function namingConventionConvert(input: string, target: NamingConventionEnum): string {
    switch (target) {
        case NamingConventionEnum.Camel:
            return toCamelCase(input);
        case NamingConventionEnum.Snake:
            return toSnakeCase(input);
        case NamingConventionEnum.Pascal:
            return toPascalCase(input);
        case NamingConventionEnum.Kebab:
            return toKebabCase(input);
        case NamingConventionEnum.ScreamingSnake:
            return toScreamingSnakeCase(input);
        case NamingConventionEnum.CamelSnake:
            return toCamelSnakeCase(input);
        case NamingConventionEnum.Dot:
            return toDotCase(input);
        default:
            console.error('Type de conversion non pris en charge');
            return input;
    }
}

/**
 * The function converts a string to camel case by removing hyphens and underscores and capitalizing
 * the following character.
 * @param {string} str - The `str` parameter is a string that represents a sentence or phrase in
 * kebab-case or snake_case format.
 * @returns a camel case version of the input string.
 */
export function toCamelCase(str: string): string {
    return str.replace(/[-_](.)/g, (_, char) => char.toUpperCase());
}

/**
 * The function converts a string from camel case to snake case.
 * @param {string} str - The `str` parameter is a string that you want to convert to snake case.
 * @returns a string converted to snake case.
 */
export function toSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
}

/**
 * The function converts a string to PascalCase by removing hyphens and underscores and capitalizing
 * the first letter of each word.
 * @param {string} str - The `str` parameter is a string that you want to convert to PascalCase.
 * @returns a string in PascalCase format.
 */
export function toPascalCase(str: string): string {
    return str.replace(/[-_](.)/g, (_, char) => char.toUpperCase()).replace(/^\w/, c => c.toUpperCase());
}

/**
 * The function converts a string to kebab case by replacing uppercase letters with a hyphen followed
 * by the lowercase version of the letter.
 * @param {string} str - The `str` parameter is a string that you want to convert to kebab case.
 * @returns a kebab case version of the input string.
 */
export function toKebabCase(str: string): string {
    return str.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
}

/**
 * The function converts a string to screaming snake case by replacing lowercase letters with uppercase
 * letters and replacing hyphens and underscores with underscores.
 * @param {string} str - The `str` parameter is a string that represents a word or phrase.
 * @returns a string in screaming snake case.
 */
export function toScreamingSnakeCase(str: string): string {
    return str.replace(/[a-z]/g, match => match.toUpperCase()).replace(/[-_]/g, '_');
}

/**
 * The function converts a string from camel case to snake case.
 * @param {string} str - The `str` parameter is a string that represents a word or phrase.
 * @returns a string in camel snake case.
 */
export function toCamelSnakeCase(str: string): string {
    return toCamelCase(str).replace(/[-_]/g, '_');
}

/**
 * The function converts a string to dot case by inserting a dot before each uppercase letter and
 * converting it to lowercase.
 * @param {string} str - The `str` parameter is a string that you want to convert to dot case.
 * @returns a new string where each uppercase letter in the input string is replaced with a dot
 * followed by the lowercase version of the letter.
 */
export function toDotCase(str: string): string {
    return str.replace(/[A-Z]/g, match => `.${match.toLowerCase()}`);
}

/**
 * The `removeDuplicateCharRegexp` function takes a string and an optional array of characters to
 * exclude, and returns a regular expression that matches any duplicate characters in the string,
 * excluding the specified characters.
 * @param {string} str - The `str` parameter is a string from which you want to remove duplicate
 * characters.
 * @param {string[]} exclude - The `exclude` parameter is an optional array of characters that you want
 * to exclude from the resulting regular expression. These characters will not be matched or removed
 * from the input string.
 * @returns a regular expression (RegExp) object.
 */
export function removeDuplicateCharRegexp(str: string, exclude: string[] = []): RegExp {
    const list = new Set(str)
    for(const c of exclude) {
        list.delete(c)
    }

    function escapeRegExp(str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    const resultat = Array.from(list).sort().map(escapeRegExp).join('')

    return new RegExp(`[${resultat}]`, 'g')
}

/**
 * The `removeDuplicateChar` function removes duplicate characters from a string and excludes specified
 * characters from the removal process.
 * @param {string} str - The `str` parameter is a string from which we want to remove duplicate
 * characters.
 * @param {string[]} exclude - The `exclude` parameter is an optional array of characters that you want
 * to exclude from the resulting string. These characters will not be considered as duplicates and will
 * be removed from the final output.
 * @returns The function `removeDuplicateChar` returns a string that contains all the unique characters
 * from the input string `str`, excluding any characters specified in the `exclude` array. The returned
 * string is sorted in alphabetical order.
 */
export function removeDuplicateChar(str: string, exclude: string[] = []): string {
    const list = new Set(str)
    for(const c of exclude) {
        list.delete(c)
    }
    return Array.from(list).sort().join('')
}

/**
 * The Ucfirst function takes a string as input and returns the same string with the first letter
 * capitalized.
 * @param {unknown} str - The parameter "str" is a string that represents the input text.
 * @returns a string with the first character capitalized.
 */
export function Ucfirst(str?: unknown): string | unknown {
    if(!str || !isString(str)) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function Lcfirst(str?: unknown): string | unknown {
    if(!str || !isString(str)) return str
    return str.charAt(0).toLowerCase() + str.slice(1)
}

/**
 * The function isEmail checks if a given string is a valid email address.
 * @param {unknown} email - The `email` parameter is a string that represents an email address.
 * @returns The function isEmail returns a boolean value. It returns true if the input email is a valid
 * email address according to the regular expression pattern, and false otherwise.
 */
export function isEmail(email: unknown): boolean {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null
}

/**
 * The function isPhone checks if a given string is a valid phone number.
 * @param {unknown} phone - The `phone` parameter is a string that represents a phone number.
 * @returns The function isPhone returns a boolean value. It returns true if the input phone number
 * matches the specified regular expression pattern for a valid phone number format, and false
 * otherwise.
 */
export function isPhone(phone: unknown): boolean {
    return String(phone)
        .toLowerCase()
        .match(
            /^(\(?\+?\d{2,3}\)?|0)[-\s\.\d]+$/im
        ) !== null
}

export function isAvs(avs: string): boolean {
    if (avs.length !== 13 || !avs.startsWith('756')) return false
    if(/(\d{3})\.?(\d{4})\.?(\d{4})\.?(\d{2})/g.test(avs)) {
        const avsNumber = avs.replace(/\D/g, '')
        let sum = 0
        for (let i = 0; i < 12; i++) {
            const digit = Number.parseInt(avsNumber.charAt(i), 10)
            if (Number.isNaN(digit)) return false
            sum += (i % 2 === 0 ? digit : digit * 3)
        }
        return (10 - (sum % 10)) % 10 === Number.parseInt(avs.charAt(12), 10)
    }

    return false
}

export class _String {

    #str: string

    constructor(str?: string) {
        this.#str = str ?? ''
    }

    apply(fn: (value: string) => string): string {
        return fn(this.#str)
    }

    pipe(fn: (value: string) => string) {
        this.#str = this.apply(fn)
        return this
    }

    pipes<
        K extends string,
        R extends [K, ...unknown[]]
    >(
        fn: (v: string, ...args: R) => string,
        replacers: R[]
    ): this {

        for(const r of replacers) {
            this.#str = fn.apply(null, [this.#str, ...r])
        }

        return this
    }

    get() {
        return this.toString()
    }

    toString(): string {
        return this.#str
    }
}

export function cString(value?: string) {
    return new _String(value)
}